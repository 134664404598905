import { Job } from "types"

const career: Job[] = [
  {
    company: "Blockdaemon",
    role: "Full Stack Developer",
    icon: "bx bxs-network-chart",
    summary:
      "Created and maintained a new website, created various API services in GoLang, integrated various Blockchain protocols into Ubiquity (a Blockchain aggregation framework)",
    dates: {
      start: 1554076800,
    },
    achievements: [
      "Created the new website using React JS",
      "Wrote the Vault GCP infrastructure in Terraform",
      "Integrated Polkadot, Algorand and Ripple protocols into Ubiquity",
      "Created a Staking Report service which generates CSV file dumps of an accounts activity for a specific protocol",
    ],
  },
  {
    company: "HSBC",
    role: "Lead Mulesoft Developer",
    icon: "bx bxs-network-chart",
    summary:
      "Integrated legacy platforms into Service now via Mulesoft middleware. Created various tools to improve productivity and coding standards",
    dates: {
      start: 1522540800,
      end: 1554076800,
    },
    achievements: [
      "Migrated legacy systems onto the Mulesoft Middleware stack",
      "Created review automation tools to check all Mule files met minimum standards and security protocols, the manual review process took upto an hour and the tool was able to reduce this to a matter of seconds",
    ],
  },
  {
    company: "Dorril Woods Ltd.",
    role: "Company Founder",
    icon: "bx bx-buildings",
    summary: "Started my own software development company alongside my wife",
    dates: {
      start: 1522540800,
    },
    achievements: [],
  },
  {
    company: "Atom Bank",
    role: "Lead Middleware Developer",
    icon: "bx bxs-network-chart",
    summary: "My role as Lead involved PoC's, Team, Sprint and Stakeholder Management as well as general programming in Mulesoft, Python and Java",
    dates: {
      start: 1488326400,
      end: 1522540800,
    },
    achievements: [
      "Developed the entire Middleware replacement, all of the teams tooling for CICD and error reporting as well as the Faster Payment pipeline"
    ],
  },
  {
    company: "Atom Bank",
    role: "Middleware Developer",
    icon: "bx bxs-network-chart",
    summary: "At Atom I have developed the mobile banking app within the Unity Engine along with the native plugins for iOS. I later moved into Middleware and Security development with the Mulesoft ESB platform",
    dates: {
      start: 1438387200,
      end: 1488326400,
    },
    achievements: [
    ],
  },
  {
    company: "Laughing Jackal",
    role: "Games Developer",
    icon: "mdi mdi-gamepad-variant-outline",
    summary: "At Laughing Jackal I created various games for Sony platforms and PC. I developed internal tooling and working on the companies propietary engine",
    dates: {
      start: 1298937600,
      end: 1438387200,
    },
    achievements: [
      "Created Playstation Vita port of Hungry Giraffe",
      "Created iOS port of Hungry Giraffe",   
      "Created Playstation Mobile port of Hungry Giraffe",
      "Created Orbit on PSP", 
      "Created OMG-Z available on PSP"
    ]
  },
  {
    company: "Fluid Pixel",
    role: "Mobile Games and Application Developer",
    icon: "mdi mdi-gamepad-variant-outline",
    summary: "Various iPhone projects. Involved programming in C++ / Object C across 6 titles. 2 titles were developed with the Unity engine",
    dates: {
      start: 1262304000,
      end: 1298937600,
    },
    achievements: [],
  },
  {
    company: "Big Bear Studios",
    role: "Mobile Games Developer",
    icon: "mdi mdi-gamepad-variant-outline",
    summary: "Various projects developed for the iPhone platform. Involved programming in C++ / Object C across 6 titles",
    dates: {
      start: 1235865600,
      end: 1259625600,
    },
    achievements: [],
  }
]

export { career }
