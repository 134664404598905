//Pages
import Home from "../pages/home"
import Games from "../pages/games"
import Apps from "../pages/apps"
import Career from "../pages/career"
import Page404 from "../pages/404"

const userRoutes = [
  // this route should be at the end of all other routes
  { path: "/", component: Home },
  { path: "/apps", component: Apps },
  { path: "/games", component: Games },
  { path: "/career", component: Career },
  
  // Redirect 
  { component: Page404 },
]

export { userRoutes }
