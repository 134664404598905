import axios from "axios"
import MockAdapter from "axios-mock-adapter"
import * as url from "../url_helper"
import { career } from "../../common/data"

const api = () => {
  // This sets the mock adapter on the default instance
  const mock = new MockAdapter(axios)

  mock.onGet(url.GET_CAREER).reply(() => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (career) {
          // Passing fake JSON data as response
          resolve([200, career])
        } else {
          reject([400, "Cannot get career data"])
        }
      })
    })
  })
}

export default api
