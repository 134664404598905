import PropTypes from "prop-types"
import { Route } from "react-router-dom"

interface Props {
  path?: string;
  component: any;
  layout: any;
  exact: boolean;
}

const Middleware = ({ component: Component, layout: Layout }: Props) => (
  <Route
    render={props => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Middleware.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
}

export default Middleware
