import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Career from "./career/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Career,
})

export default rootReducer
