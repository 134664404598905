import React from "react"
import { Container } from "reactstrap"

//i18n
import { useTranslation } from "react-i18next"

interface Props {
}

const Home = (props: Props) => {
  const { t } = useTranslation()
  
  let about = t("home.about")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="text-muted font-size-14 m-3">
            <p>{t("home.welcome")}</p>
            <hr />
            {about.split("\n").map((item: string, i: number) => (
              <p key={`about-${i}`}>{item}</p>
            ))}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Home
