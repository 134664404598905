import PropTypes from "prop-types"
import React from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes } from "./routes/routes"

// Import all middleware
import Middleware from "./routes/middleware"

// layouts Format
import Layout from "./components/layout"

// Import scss
import "./assets/scss/theme.scss"

import api from "./helpers/mock/api"
import { AppState } from "types"

// Activating fake backend
api()

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {userRoutes.map((route, idx) => (
            <Middleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = (state: AppState) => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
