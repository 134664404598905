import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { GET_CAREER } from "./action_types"
import { getCareerSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getCareer } from "../../helpers/api_resources"

function* getCareerData() {
  try {
    const response = yield call(getCareer)
    yield put(getCareerSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

export function* watchCareer() {
  yield takeEvery(GET_CAREER, getCareerData)
}

function* CareerSaga() {
  yield all([fork(watchCareer)])
}

export default CareerSaga
