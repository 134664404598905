import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col>{new Date().getFullYear()} © Gavin Woods.</Col>
            <Col className="d-flex flex-row-reverse align-self-center">
              <a href="https://www.linkedin.com/in/gavin-woods-fsd">
                <i className="bx bxl-linkedin-square mr-2 font-size-24"></i>
              </a>
              <a href="https://gitlab.com/InfiniteDaremo">
                <i className="bx bxl-gitlab mr-2 font-size-24"></i>
              </a>
              <a href="mailto:gavinwoods.id@gmail.com">
                <i className="bx bx-mail-send mr-2 font-size-24"></i>
              </a> 
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
