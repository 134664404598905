import React, { useEffect } from "react"
import { Container } from "reactstrap"

import { connect } from "react-redux"

import moment from "moment"

//i18n
import { useTranslation } from "react-i18next"

import { getCareer } from "../store/actions"
import { Job, AppState } from "types"

interface Props {
  career: Job[];
  getCareer(): void;
}

const Career = (props: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
    props.getCareer()
  }, [])

  const careerDates = (job: Job) => {
    const startDate = moment.unix(job.dates.start).format("MMM YYYY")
    const endDate = job.dates.end
      ? moment.unix(job.dates.end).format("MMM YYYY")
      : "Present"
    return `${startDate} - ${endDate}`
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="card-body">
            <h4 className="card-title mb-4">{t("career.title")}</h4>
            <div>
              <ul className="verti-timeline list-unstyled">
                {props.career.map((job, i) => (
                  <li className="event-list" key={`job-li-${i}`}>
                    <div className="event-timeline-dot">
                      <i
                        className={`bx ${
                          !job.dates.end
                            ? "bx-right-arrow-circle"
                            : "bx-up-arrow-circle"
                        }`}
                      ></i>
                    </div>
                    <div className="media">
                      <div className="mr-3">
                        <i className={`bx ${job.icon} h2 text-primary`}></i>
                      </div>
                      <div className="media-body">
                        <div>
                          {/* Company Name */}
                          <h5 className="font-size-14">{job.company}</h5>
                          <h6 className="font-size-12">{job.role}</h6>
                          {/* Dates */}
                          <h6 className="font-size-12">{careerDates(job)}</h6>
                          <p className="text-muted">{job.summary}</p>
                          <ul className="p-1">
                            {job.achievements.map((s: string, i: number) => (
                              <li key={`achievement-${i}`}>{s}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    career: state.Career.career,
  }
}

export default connect(mapStateToProps, { getCareer })(Career)
