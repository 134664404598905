import React from "react"
import { Card, CardBody, Container, Row, Col } from "reactstrap"

//Import Images
import flame_over from "assets/images/games/flame_over.jpg"
import omgz from "assets/images/games/omgz.jpg"

const games = [{
  title: "Flame Over",
  image: flame_over,
  link: "https://store.steampowered.com/app/345080/"
}, {
  title: "OMGZ",
  image: omgz,
  link: "http://store.steampowered.com/app/259870/"
}]

const Games = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            {games.map((game, i) => {
              return (
                <Col xl="6" key={`game-${i}`}>
                  <Card className="overflow-hidden border">
                    <CardBody>
                      <div className="justify-content-center text-center">
                        <h4 className="mb-3">{game.title}</h4>
                        <a href={game.link} target="_blank">
                          <img className="mx-auto rounded img-fluid card-img-top" src={game.image}/>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}

          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Games
