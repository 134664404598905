import { GET_CAREER, GET_CAREER_SUCCESS } from "./action_types"

export const getCareer = () => {
  return {
    type: GET_CAREER,
    payload: null,
  }
}

export const getCareerSuccess = career => {
  return {
    type: GET_CAREER_SUCCESS,
    payload: career,
  }
}
